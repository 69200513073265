<template>
  <div class="notification-container" v-if="notification">
    <div>
      <h5>{{ notification.title }}</h5>
      <h6>{{ notification.description }}</h6>
    </div>
    <b-form-checkbox
      v-model="notification.value"
      name="check-button"
      size="lg"
      switch
      @change="handleChangeNotification"
    >
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    handleChangeNotification() {
      this.$emit("handleChangeNotification", this.notification);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
