<template>
  <div v-if="user" a-tag="notificationCard">
    <notification
      v-for="(setting, index) in user.settings"
      :key="index"
      :notification="setting"
      @handleChangeNotification="handleChangeNotification"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Notification from "@/components/Application/MyProfile/Notification";
import { patchUserRequest } from "@/api/register";
export default {
  components: {
    Notification,
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast"]),

    handleChangeNotification() {
      const entries = Object.values(this.user.settings);
      const result = entries?.reduce((obj, cur) => ({ ...obj, [cur.key]: cur.value }), {});

      const body = {
        id: this.user.id,
        settings: result,
      };
      this.ApiService(patchUserRequest(body)).then(({ data }) => {
        const params = {
          token: this.token,
        };
        this.SetUser(params);
        this.ShowToast({
          title: this.$t("GLOBAL_SUCCESS"),
          type: "success",
          message: data.meta.custom.message,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
